<script setup lang="ts">
import SVGFilter from '@mop/shared/images/misc/filter.svg?component';
import SVGSearch from '@mop/shared/images/misc/search.svg?component';
import { getImage } from '@mop/cms/utils/utils';
import { scrollToPosition } from '@mop/shared/utils/util';
import type { Timer } from '@mop/types';
import JobListItem from './JobListItem.vue';
import type { SeoBreadcrumb, CmsContentElementModel } from '@/types/cms';
import type { JobModel } from '@/types/jobModel';

defineOptions({
  name: 'JobSearch',
});

const { $urls, $mopI18n, $resize, $breakpoint, $gtm, $storyblokLivePreview } = useNuxtApp();
const { getBreadcrumbSchema } = useMopSeo();
const route = useRoute();
const router = useRouter();

const classNameRef = ref(['']);
const loadingRef = ref({
  loading: true,
});
const formRef: Ref<any> = ref({
  search: route?.query.search,
});
const resultRef: Ref<any> = ref({
  list: [],
  pages: 0,
  total: 0,
});
let searchTrackTimer: Timer;
const ITEMS_PER_PAGE = 15;
const overlayName = 'JobFiltersOverlay';
const filtersOverlay = useMopOverlay();
const { getCmsStory, trackPageView, cmsStoryModelRef } = useMopCms();
const { handleHeadForCmsPage } = useMopSeo();
const {
  fetchAllJobs,
  handleBuildUrlParams,
  addFilters,
  getSelectedFilters,
  getFilteredJobList,
  recalculateFilters,
  selectedFiltersRef,
} = useMopJob();
const { initTransition } = useMopPageTransitionClient();
initTransition(classNameRef, loadingRef);

onMounted(async () => {
  await Promise.all([getCmsStory(constants.STORYBLOK.STORY_PATHS.JOB_SEARCH_PAGE), fetchAllJobs()]);
  trackPageView('Career');
  addFilters();
  paginateResult();
  loadingRef.value.loading = false;
});

watch(
  () => route.query?.page,
  () => {
    scrollToPosition(0);
  },
);
watch(() => route.fullPath, paginateResult);
watch(
  () => formRef.value.search,
  async (search) => {
    const { query } = handleBuildUrlParams({
      search,
      page: 1,
    });
    await router.push({
      path: $mopI18n.localePath($urls.CAREER_PAGE),
      query,
    });
  },
);
watch(
  () => $resize.viewportOrientationRef.value,
  async () => {
    if (filtersOverlay?.activeOverlayRef.value?.componentName === overlayName && $breakpoint.isLargeRef.value) {
      return await filtersOverlay.closeAll();
    }
  },
);
watch(() => cmsStoryModelRef.value, initPromoTiles);

function getPageNum() {
  let pageNum = 1;
  const queryPage: any = route.query.page;
  if (!isNaN(parseInt(queryPage))) {
    pageNum = queryPage;
  }

  return Math.max(1, pageNum);
}

function paginateResult() {
  formRef.value.search = route?.query?.search;
  const filteredList: JobModel[] = getFilteredJobList(formRef.value.search);
  selectedFiltersRef.value = getSelectedFilters();
  recalculateFilters();

  const pageNum = getPageNum();
  const page = Math.max(1, pageNum);
  const from = (page - 1) * ITEMS_PER_PAGE;
  const to = page * ITEMS_PER_PAGE;
  resultRef.value.jobList = filteredList.slice(from, to);
  resultRef.value.list = filteredList.slice(from, to);
  resultRef.value.total = filteredList.length;
  resultRef.value.pages = Math.ceil(filteredList.length / ITEMS_PER_PAGE);
  initPromoTiles();
  if (resultRef.value.pages < pageNum) {
    router.push(
      handleBuildUrlParams({
        page: resultRef.value.pages,
      }),
    );
  }

  clearTimeout(searchTrackTimer);
  const searchTerm = formRef.value.search;
  const delay = searchTerm ? 2000 : 0;
  searchTrackTimer = setTimeout(() => {
    $gtm.trackEvent({
      eventName: 'view_item_list',
      customValue: {
        items: [resultRef.value.jobList.map((item: JobModel) => item.getGtmTrackData())],
      },
    });

    searchTerm &&
      $gtm.trackEvent({
        eventName: 'vacancy_search_results',
        parameters: [$gtm.getCurrentPageType(), searchTerm, resultRef.value.total ? 'job_results' : 'no_result', page],
      });
  }, delay);
}

function handleJobClickTracking(job: JobModel) {
  $gtm.trackEvent({
    eventName: 'select_item',
    customValue: {
      items: [job.getGtmTrackData()],
    },
  });
  const searchTerm = formRef.value.search;
  searchTerm &&
    $gtm.trackEvent({
      eventName: 'vacancy_search_clicked',
      parameters: [$gtm.getCurrentPageType(), searchTerm, 'job_results'],
    });
}

function initPromoTiles() {
  if (!resultRef.value?.jobList?.length) {
    return;
  }
  const elementList: CmsContentElementModel[] = cmsStoryModelRef.value.getContentElements('body');
  if (elementList.length === 0) {
    return;
  }

  resultRef.value.list = [...resultRef.value.jobList];
  const page = getPageNum();
  const paginatedPosition = (page - 1) * ITEMS_PER_PAGE;
  const arrayPositionMax = resultRef.value.jobList.length + paginatedPosition;
  elementList.forEach((item: CmsContentElementModel) => {
    const type = item.getType();
    if (type !== 'CompanyCareerPromoItem') {
      return;
    }
    const data = item.getData();
    const position = parseInt(data.position);
    const arrayPosition = position - 1 - paginatedPosition;

    if (
      position <= arrayPositionMax &&
      position > paginatedPosition &&
      position <= paginatedPosition + ITEMS_PER_PAGE
    ) {
      resultRef.value.list.splice(arrayPosition, 0, {
        image: getImage(data.image),
        type,
        data,
        getId: () => data._uid,
      });
    }
  });
}

async function handleJobFilersOverlay() {
  if (filtersOverlay?.activeOverlayRef.value?.componentName === overlayName) {
    return await filtersOverlay.closeAll();
  }
  await filtersOverlay.open({
    componentName: overlayName,
    type: 'right',
    overrideParams: {
      lockScroll: true,
      showClose: true,
      forceRender: true,
      shadowColor: 'dark',
      persist: true,
    },
  });
}

function trackSearchState(name: string, attrs?: string) {
  $gtm.trackEvent({
    eventName: `vacancy_search_${name}`,
    parameters: [$gtm.getCurrentPageType(), attrs],
  });
}

function getLocalePathByPage(pageNumber: number) {
  const query = { ...route.query };
  if (pageNumber === 1) {
    delete query.page;
  } else {
    query.page = pageNumber.toString();
  }
  const fullPath = router.resolve({ query }).fullPath;
  return fullPath;
}

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  const startCreatingLink: string = $mopI18n.localePath($urls.CAREER_START_CREATING);
  const careerLink: string = $mopI18n.localePath($urls.CAREER);

  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.blog.name_career'),
      url: careerLink,
    },
    {
      name: $mopI18n.t('locale.jobs.start_creating_with_us'),
      url: startCreatingLink,
    },
    {
      name: $mopI18n.t('locale.jobs.search_button'),
      url: route.path,
    },
  ];

  return {
    ...handleHeadForCmsPage(cmsStoryModelRef.value),
    ...getBreadcrumbSchema(breadcrumbs),
  };
});

useHead(seoHeadRef);
</script>

<template>
  <div :class="['job-search', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <MopCmsContentElementsSlot
        id="job-search__content-top"
        no-padding
        :elements="cmsStoryModelRef.getContentElements('bodyTop')"
      />

      <div class="job-search__actions">
        <form class="job-search__search-form" @submit.prevent.stop>
          <div class="job-search__search-wrap">
            <div class="job-search__search-wrap-block job-search__search-wrap-block--cropped">
              <SVGSearch class="job-search__search-form-icon" width="25" height="16" />
              <input
                v-model="formRef.search"
                class="job-search__search-form-input"
                autocomplete="off"
                spellcheck="false"
                :placeholder="$mopI18n.t('locale.search.placeholder')"
                @focus="trackSearchState('start')"
                @blur="trackSearchState('committed', String(formRef.search || ''))"
              />
            </div>
            <div class="job-search__search-wrap-block">
              <button
                v-if="formRef.search"
                type="reset"
                class="job-search__selected-filter"
                @click="formRef.search = ''"
              >
                <i class="job-search__selected-filter-icon icon icon--x" />
              </button>
              <button class="job-search__search-form-button">
                {{ $mopI18n.t('locale.search.search') }}
              </button>
            </div>
          </div>
        </form>

        <button
          class="job-search__filter-button"
          :data-items="selectedFiltersRef.length > 0 ? selectedFiltersRef.length : ''"
          @click="handleJobFilersOverlay"
        >
          <div class="job-search__filter-button-text">
            {{ $mopI18n.t('locale.jobs.filters') }}
          </div>
          <SVGFilter width="24" height="24" />
        </button>
      </div>

      <div class="job-search__wrapper">
        <MopJobFilters class="job-search__filters" :count="resultRef.total" />
        <div class="job-search__results">
          <div v-if="!resultRef.list.length">
            {{
              selectedFiltersRef.length
                ? $mopI18n.t(`locale.search.empty_filters`)
                : $mopI18n.t(`locale.search.empty`, [formRef.search])
            }}
          </div>
          <div class="job-search__job-list">
            <template v-for="jobItem in resultRef.list">
              <UiImage
                v-if="jobItem && jobItem.type"
                :key="jobItem.getId()"
                v-storyblok-editable="jobItem.data"
                :image="jobItem.image"
                type="job-preview"
                cover
                class="job-search__item"
              />
              <template v-else>
                <JobListItem
                  :key="jobItem.getId()"
                  :job="jobItem"
                  class="job-search__item"
                  @click="() => handleJobClickTracking(jobItem)"
                />
              </template>
            </template>
          </div>
          <UiPagination
            v-if="resultRef.pages > 1"
            :key="`pagination-${resultRef.pages}-${$route.query.fullPath}-${$route.query.page}`"
            :total-pages="resultRef.pages"
            :page="String($route.query.page || 1)"
            class="job-search__pagination"
            :get-locale-path-by-page="getLocalePathByPage"
          />
        </div>
      </div>

      <MopCmsContentElementsSlot
        id="job-search__content-bottom"
        no-padding
        :elements="cmsStoryModelRef.getContentElements('bodyBottom')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.job-search {
  padding: 0 $global-padding;
}

.job-search__wrapper {
  margin: $space40 0;
  display: grid;
  grid-template-areas: 'filters results';
  grid-template-columns: 286px 1fr;
  column-gap: $space-8;

  @include apply-upto(medium) {
    grid-template-areas: 'results';
    column-gap: 0;
    grid-template-columns: 1fr;
    margin-top: $space30;
  }
}

.job-search__actions {
  display: flex;
  margin-top: $space30;
  height: 40px;
}

.job-search__search-form {
  position: relative;
  flex: 1;
  height: 100%;
}

.job-search__filters {
  grid-area: filters;

  @include apply-upto(medium) {
    display: none;
  }
}

.job-search__results {
  grid-area: results;
}

.job-search__job-list {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  column-gap: $space-8;
  row-gap: $space-8;
  align-content: start;

  @include apply-upto(extralarge) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @include apply-upto(medium) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }

  @include apply-upto(small) {
    grid-template-columns: 1fr;
  }
}

.job-search__search-wrap {
  border: 1px solid $black;
  padding: 0 5px;
  display: inline-flex;
  width: 450px;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  @include apply-upto(medium) {
    display: flex;
    width: 100%;
  }
}

.job-search__search-wrap-block {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.job-search__search-wrap-block--cropped {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  flex: 1;
}

.job-search__search-form-icon {
  position: absolute;
  top: 50%;
  margin-top: -8px;
}

$input-margin-left: $space30;
$input-margin-right: $space10;
.job-search__search-form-input {
  @include text-style(strong);

  border: 0;
  text-transform: uppercase;
  color: $black;
  height: 100%;
  margin-left: $input-margin-left;
  margin-right: $input-margin-right;
  width: calc(100% - #{$input-margin-left} - #{$input-margin-right});
  padding: 0;

  &:not(focus) {
    text-overflow: ellipsis;
  }
}

.job-search__search-form-button {
  @include text-style(strong);

  background: $black;
  color: $white;
  text-transform: uppercase;
  margin: 0 -5px;
  height: 100%;
  padding: 0 $space30;
  border: 0;
  cursor: pointer;

  @include apply-upto(medium) {
    display: none;
  }
}

.job-search__selected-filter {
  @include link-not-underlined(2px);

  margin: -#{$space10} $space10 -#{$space10} 0;
  display: inline-flex;
}

.job-search__filter-button {
  @include exponent-text-style(0);

  background: transparent;
  border: 1px solid;
  padding: $space5 $space10;
  margin: 0;
  margin-left: $space5;
  display: flex;
  cursor: pointer;
  align-items: center;

  &::after {
    left: auto;
    right: 6px;
  }

  @include apply-from(large) {
    display: none;
  }
}
.job-search__filter-button-text {
  @include text-style(strong);
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 7px;
}

.job-search__item {
  border: 1px solid $black;

  @include apply-from(medium) {
    aspect-ratio: 5 / 6;
  }
}

.job-search__pagination {
  margin-top: $space40;
}
</style>
